import React, { Fragment } from 'react';
import Footer from '../components/Footer';
import SiteConstant from '../utils/siteconstant';

function PrivacyPolicy(props) {
    return (
        <Fragment>
            <div className="full-header">
                <header>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="w-100 d-flex align-items-center justify-content-between mob-header">
                                
                                    <div className="header_em">
                                        <ul className="d-flex">
                                            <li></li>
                                            <li></li>
                                        </ul>
                                    </div>
                                    <div className="header_em">
                                        <ul className="d-md-flex mt-1">
                                            <li><a href={"mailto:support@"+window.location.hostname}><i className="fa fa-envelope" aria-hidden="true"></i> support@{(window.location.hostname).replace("www.","")}</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="sec_header">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <nav className="navbar navbar-expand-lg navbar-light d-flex justify-content-between">
                                    <a className="navbar-brand" href="/"><img src={'.'+SiteConstant[(window.location.hostname).replace("www.","")].logo} alt="" /></a>
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <div className="collapse navbar-collapse justify-content-end custom_style" id="navbarNavAltMarkup">
                                    <div className="navbar-nav align-items-center">
                                        <div style={{height:'30px'}}></div>
                                    </div>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="page-content-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-center term-policy-headding">
                                <h1>DATA COLLECTION & <span>USE POLICY</span></h1>
                            </div>
                        </div>
                        <div className="text-dark">
                            <b><i>Our Data Collection &amp; Use Policy ("Policy") is effective as of July 1st, 2022.</i></b>
                            <p><i>This Policy sets forth how Benefit Specialists, LLC, a Delaware limited liability company who operates “{SiteConstant[(window.location.hostname).replace("www.","")].title}” (“We” or “Us"), collects and uses the information you provide to us and certain other information that we automatically collect through your access and use of {(window.location.hostname).replace("www.","")} (“Website”), or through any communications with us generally.</i></p>
                            <br/>
                            <h5><b>HOW TO CONTACT US</b></h5>
                            <p>If you have any general questions or concerns about this Policy, you can also email us at <b>support@{(window.location.hostname).replace("www.","")}.</b></p>
                            <br/>
                            <h5><b>MODIFICATIONS TO THIS POLICY</b></h5>
                            <p>If we decide at any time to change this Policy, we will revise the effective revision date posted at the top of this Policy and we will also notify you by posting a notice of the change prominently on our home page (we may also elect to notify you upon the next time you log-in to access our Website after the effective date of the change(s)). Failure to opt-out after receiving notice and following the opt-out instructions contained in the notice shall be deemed to be your assent to any such changes.</p>
                            <br/>
                            <h5><b>HOW WE COLLECT PERSONAL INFORMATION</b></h5>
                            <b>Scheduling Process &amp; Scheduling Data</b>
                            <p>We collect and store your email address, your first and last name and your phone number (collectively “PII”) during the scheduling process. We use the PII we do collect and store as set forth in the remainder of this Policy. We also collect your age range.</p>
                            <br/>
                            <p>We collect your Website use information in connection with the specific appointments you schedule (i.e. the date, time and the provider you interact with) as a user with any third-party provider using our platform (“Scheduling Data”) in connection with your use of our platform. We store your Scheduling Data on our servers indefinitely.</p>
                            <br/><b>Website Forms</b>
                            <p>When you provide any PII or other personally identifiable data through any form contained on our Website in order to contact us, we collect and store this information as expressly set forth in this Policy.</p>
                            <br/>
                            <h5><b>HOW WE AUTOMATICALLY COLLECT DATA</b></h5>
                            <p>When you access our Website, we may collect and store through our host provider certain data sent automatically by your browser using standard web server logs and automatic data collection tools such as cookies and web beacons. This information may include your IP address, which may tell us your general location information such as your country and sometimes your city, state and/or zip code, the domain name from which you access our Website, your browser type and language, the pages you access, the date and time you access pages on our Website and your operating system version. We also may determine what technology is available through your browser in order to provide you with the most appropriate version of any of our web pages.</p>
                            <br/><b>Cookies &amp; Similar Tracking Technologies</b>
                            <p>We use cookies, web beacons and other automatic data collection tools in the operation of our Website. Cookies and similar collection tools are files with a small amount of data, which includes a unique identifier. Cookies are sent to your browser from a website and transferred to your device when you log in. Visiting the login page while not authenticated, or visiting pages that don't require login, like shared reports, does not set cookies. We reserve the right to monitor your use of our Website and track you as described in this Cookies policy by the use of any automatic data collection tools at all times and you consent to the use of these tools when you use and access our Website. </p>
                            <br/>
                            <p><b>Our Website use "first-party cookies".</b> (Third-party cookies are cookies that are set by a third-party source other than our Website.) We also use both "persistent cookies" that remain on your device for the period of time specified in the cookie (these cookies are activated each time you visit our Website), and "session cookies," which allows us to track your actions on our Website during a browser session.</p>
                            <br/>
                            <p>The cookies and tracking technologies that may be used on our Website can be broadly divided into the following categories:</p>
                            <p><b>- Necessary cookies  help</b> make our Website usable by enabling basic functions like page navigation and access to secure areas of the Website. Our Website cannot function properly without these cookies.</p>
                            <br/>
                            <p><b>- Statistic cookies</b> help us understand how visitors interact with our Website by collecting and reporting information anonymously.</p>
                            <br/><b>I. First-Party Cookies/Tracking Technologies</b>
                            <p><b>A. Website Session Management:</b> enables users to log-in and authenticates a user or in order to store specific user preferences and settings.</p>
                            <br/>
                            <p><b>B. Google Analytics Services:</b> We may use web analytics services, such as Google Analytics, in order to collect information. This information is collected in the aggregate and may include the number of page visits, duration, sources of traffic, visited pages, preferred sections, keywords used to find our Website, technical details of visitors’ devices, our visitors’ age, gender, interests, and location. Google Analytics only shows us a unique user ID and does not share your personal information, Google user name or IP address, which means you can't really see and analyze how specific users behave on our Website. We pass a "first party cookie" onto your browser directly from our Website when we use Google Analytics. You may opt-out of Google Analytics by using Google's Browser Add-on at https://tools.google.com/dlpage/gaoptout.</p>
                            <br/><b>II. Third-Party Cookies</b>
                            <p>Currently, we do not use any specific third-party cookies on our Website.</p>
                            <br/><b>III. How To Manage Cookies</b>
                            <p>You can block our cookies or third-party cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies), you will not be able to use our Website in any meaningful manner as intended. Your Internet browser allows you to change your cookie settings in the 'options' or 'preferences' menu of your browser.</p>
                            <br/><b>Social Media and Plugins</b>
                            <p>We may also provide access to services or features on our Website that enable you to share information with certain social networks and interact with us on our social media fan pages or other social media platforms that we may utilize. This may include the Facebook "Like" or share buttons or the Facebook "Comments" feature, social media "follow-us" buttons, etc., all of which are administered via third party applications or scripts that run on our Website. Your use of these features may result in the collection or sharing of information about you by these third party social media Website. Your interactions with these social media features are governed by the privacy policy of the party that provides the specific feature or application. </p>
                            <br/>
                            <h5><b>HOW WE USE/SHARE YOUR DATA</b></h5>
                            <b>Third-Party Providers</b>
                            <p>We provide your PII as described in this Policy to any of third-party benefits/services providers that you connect with and these providers are not owned or operated by us.</p>
                            <br/><b>Promotional &amp; Informational Use of PII</b>
                            <p>We use the email address provided by You to send periodic promotional emails about any of our related or new services. We use third party service providers necessary for providing our email marketing services and similar content marketing services to our users.</p>
                            <br/><b>Email Marketing Opt-Out</b>
                            <p>It is our intention to fully comply with the CAN-SPAM Act of 2003. <b>If you no longer wish to have any emails sent to you, please click on the ’unsubscribe’ button at the bottom of any and all emails to opt-out.</b> This does not apply to any transactional based emails sent by us that we are required to send to you and fulfillment of any contractual obligations to deliver any services to you.</p>
                            <br/><b>Administrative/Customer Service Support</b>
                            <p>We share our files and some PII we collect through the Website directly with third party independent contractors that provide customer support and other administrative services on our behalf. We will only contract such third parties for the limited purpose of assisting us to operate this Website and/or provide support or administrative services in conjunction with any products or services that we may offer through this Website from time to time. This limited access would be incidental to the services being provided by such third party contractors and no permission or consent shall be granted by us to use or disclose this information in any manner whatsoever, other than as necessary to provide the applicable services to us. We require that our independent contractors commit to uphold the privacy of any PII or other information regarding our Website visitors or customers that we may share with them. We will require that each of our contractors agree not to use or disclose any PII, which may be disclosed to or shared with these parties during the course of their duties to us, except as absolutely necessary to perform their duties to us.</p>
                            <br/><b>Marketing &amp; Business Consultants</b>
                            <p>We may share general aggregated website use information, Scheduling Data in association with certain PII, or certain PII by itself, that we collect through your use of the Website with our marketing and/or business consultants (or partners) and any other similar such parties. We do this strictly in order to improve upon any aspect of our marketing strategies, sales practices or upon some other business practice. We may also share this information with third-party website and SEO consultants in order to help improve the functionality or accessibility of the Website, search engine visibility or to help us increase our web traffic. This limited access is incidental to the services being provided by such third parties and no permission or consent is granted by us to use or disclose this information in any manner other than as necessary to provide the applicable services or responsibilities to us.</p>
                            <br/><b>Do We Sell Your PII?</b>
                            <p>We do not directly share or sell any PII or any aggregated or non-aggregated Scheduling Data with any advertising agencies, direct marketers, lead generation companies or ad networks. We make every effort to respect the your privacy and purchase history data and we will never sell, license or disclose any PII or any Scheduling Data, by itself, or bundled with any Website use information, or other information you provide directly, or that we collect automatically, to any third parties, for any purpose not expressly set forth in this Policy without your prior express written consent.</p>
                            <br/><b>Required Disclosures</b>
                            <p>We may disclose certain PII if i) requested via a subpoena, court order, or local, state, federal or international law enforcement request to release any such information; or ii) in order to investigate and help prevent security threats, fraud or other malicious activity; or iii) to protect our rights or protect our property, or any of the rights of our customers or other third parties.</p>
                            <br/><b>Communications</b>
                            <p>When you visit our Website, submit posts on our forums or send emails to us, you are communicating with us electronically. You consent to receive communications from us electronically. You agree that all agreements, notices and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing. All posts submitted by you on the forums of our Website shall not be treated as confidential and shall be deemed to be accessible to the general public. You should not transmit any information in your posts that you intend only the intended recipient(s) to read. Notice is hereby given that all messages or electronic data transmitted onto our Website can and may be read by us, regardless of whether we are the intended recipient of any such message(s).</p>
                            <br/>
                            <h5><b>THIRD-PARTY PROVIDER PRIVACY PRACTICES</b></h5>
                            <p>We facilitate your connection with third-party service providers that have  access to your PII. <b>WE HAVE NO CONTROL OVER AND WE SHALL NOT BE LIABLE TO YOU IN ANY MANNER WHATSOEVER FOR THE DATA COLLECTION AND USE PRACTICES OF SUCH THIRD PARTIES.</b> You should carefully read any third-party privacy policy made available to you to understand how any PII may be used.</p>
                            <br/>
                            <h5><b>HOW WE PROTECT DATA</b></h5>
                            <p>We take the security of your data very seriously. All traffic between your browser and our servers is encrypted during transmission using Secure Sockets Layer (SSL) encryption. However, we make no guarantee, warranty or representation that any information provided through our Website will remain secure at all times from any illegal or unauthorized access of any data including any PII or Scheduling Data by third parties. We will report any unauthorized access to your information promptly upon discovery, and we will use our best efforts to remedy any security vulnerability that contributed to unauthorized access.</p>
                            <br/>
                            <h5><b>GENERAL INFORMATION</b></h5>
                            <b>Information Storage And International Transfer</b>
                            <p>Any PII and all other information that we collect on our Website will be stored and processed in our servers located in the United States. If you reside outside the United States, you consent to the collection, transfer, storage, and processing of any information from your country to the United States.</p>
                            <br/><b>How to View Your PII</b>
                            <p>You can request any PII at any time and can view all Scheduling Data by emailing us at <b>support@{(window.location.hostname).replace("www.","")}.</b></p>
                            <br/><b>Successors</b>
                            <p>Upon any acquisition, merger, sale or other change of control of our business/organization, we reserve the right to transfer any PII you have submitted to our Website as part of the property owned by us to our successor resulting from such merger, acquisition, sale, etc. In the event of our bankruptcy, insolvency, reorganization, receivership, or assignment for the benefit of creditors, or the application of laws or equitable principles affecting creditors' rights generally, We reserve the right to transfer your PII to protect any of our rights or as required by law.</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </Fragment>
    );
}

export default PrivacyPolicy;